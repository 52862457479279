import React from "react";
import Helmet from "react-helmet";
import { Global, css } from "@emotion/react";

import Header from "./Header";
import ContentViewer from "./ContentViewer";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <>
      <Global
        styles={css`
          html {
            font-size: 62.5%;
            box-sizing: border-box;
            font-family: "Monda", sans-serif;
            max-width: 100vw;
          }
          body {
            margin: 0;
            padding: 0;
            background-color: black;
            max-width: 100vw;
          }

          *,
          *:before,
          *:after {
            box-sizing: border-box;
          }
          ul {
            list-style: none;
          }
          a {
            text-decoration: none;
          }
          p {
            line-height: 1.7;
          }
          h1,
          h2,
          h3 {
            margin: 0;
            line-height: 1.5;
          }

          h3 {
            font-weight: 400;
            font-size: 4.5rem;
          }
          img {
            max-width: 100%;
          }
          :root {
            --background-theme: #001f2c;
            --background-theme-opacity: rgba(0, 31, 44, 0.92);
            --background-technologies: #0d4a63;
            --background-role: #e38a24;
            --bg-btn-project: #e6b579;
            --text-btn-project: #00364b;
            --bg-btn-github1: #ffffff;
            --text-btn-github1: #00364b;
            --bg-btn-github2: #000000;
            --text-btn-github2: #ffffff;
            --accent-dark: #965100;
            --accent-light: #e38a24;
          }
        `}
      />
      <Helmet>
        <title>Portfolio - Pablo Núñez Ruiz</title>
        <meta
          name="description"
          content="Portfolio-Website from Pablo Núñez Ruiz, Fronted Webdeveloper"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;0,700;1,200&family=Ubuntu:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Monda:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap"
          rel="stylesheet"
        />
        <script
          src="https://kit.fontawesome.com/6fd16049e3.js"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <Header />
      <ContentViewer children={children} />
      <Footer />
    </>
  );
};

export default Layout;
