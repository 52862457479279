import React from "react";
import styled from "@emotion/styled";
import Pulse from "react-reveal/Pulse";

import Navigation from "./Navigation";

const HeaderBackground = styled.div`
  background: var(--background-theme-opacity);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: space-around;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1440px;
  justify-content: space-between;
  align-items: center;
  color: white;
  @media (min-width: 768px) {
    flex-direction: row;
    height: 15rem;
    max-width: 95%;
  }
`;

const Titlecontainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  height: fit-content;
  justify-content: space-around;
  align-items: center;
  margin: 1.5rem 0;
  @media (min-width: 768px) {
    max-width: 35%;
  }

  h1 {
    color: var(--background-theme);
    background-color: var(--accent-light);
    font-family: "Syncopate", sans-serif;
    font-size: 3rem;
    font-weight: 700;
    padding: 0 1rem;
    border-radius: 0 2rem 0 2rem;
    text-transform: uppercase;
    text-align: center;
  }
  h2 {
    font-size: 2.9rem;
    font-weight: 700;
    text-align: center;
    color: var(--accent-light);
  }
`;

const Header = () => {
  return (
    <HeaderBackground>
      <HeaderContainer>
        <Titlecontainer>
          <Pulse>
            <h1>Pablo Núñez Ruiz</h1>
          </Pulse>
          <h2>Webdeveloper Portfolio</h2>
        </Titlecontainer>

        <Navigation />
      </HeaderContainer>
    </HeaderBackground>
  );
};

export default Header;
