import React from "react";
import styled from "@emotion/styled";

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-width: 100vw;
  height: 15rem;
  background-color: var(--background-theme);
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const FooterInfo = styled.div`
  color: var(--accent-light);
  font-size: 1.5rem;
  span {
    color: white;
  }
  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const FooterContact = styled.nav`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: space-around;
  a {
    font-size: 2.5rem;
    color: white;
    &:hover {
      color: #6bc2e8;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: right;
    a {
      margin: 0 2rem;
    }
  }
`;

const copyDate = new Date();

const Footer = () => {
  return (
    <FooterContainer>
      <FooterInfo>
        © {copyDate.getFullYear()} <span>Pablo Núñez Ruiz - Webdeveloper</span>
      </FooterInfo>
      <FooterContact>
        <a
          href="https://www.linkedin.com/in/pablo-n%C3%BA%C3%B1ez-ruiz-2a3a27211/"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        <a href="https://github.com/Panuruiz" target="_blank" rel="noreferrer">
          GitHub
        </a>
      </FooterContact>
    </FooterContainer>
  );
};

export default Footer;
