import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

const NavigationContainer = styled.nav`
  width: 50%;
  height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  z-index: 9;
  @media (min-width: 768px) {
    justify-content: right;
  }
`;

const NavLink = styled(Link)`
  color: #fff;
  font-weight: 400;
  font-family: "Kanit", sans-serif;
  font-size: 1.7rem;
  text-decoration: none;
  padding: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: border-bottom 150ms ease-in-out 90ms;
  position: relative;
  &:last-of-type {
    margin-right: 0;
  }
  &.active-page {
    color: var(--accent-light);
    position: relative;
  }
  &::before {
    content: "";
    display: block;
    height: 0.3rem;
    background: white;
    position: absolute;
    top: -0.1rem;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform ease-in-out 250ms;
  }
  &.active-page::before {
    content: "";
    display: block;
    height: 0.3rem;
    background: var(--accent-light);
    position: absolute;
    top: -0.1rem;
    left: 0;
    right: 0;
    transform: scale(1, 1);
  }
  &:hover::before {
    transform: scale(1, 1);
  }
  @media (min-width: 768px) {
    font-size: 2.7rem;
    margin: 0 1rem;
    &::before {
      height: 0.5rem;
    }
    &.active-page::before {
      height: 0.5rem;
    }
  }
`;

const Navigation = () => {
  return (
    <NavigationContainer>
      <NavLink to={"/"} activeClassName="active-page">
        HOME
      </NavLink>
      <NavLink to={"/projects"} activeClassName="active-page">
        PROJECTS
      </NavLink>
      <NavLink to={"/about"} activeClassName="active-page">
        ABOUT
      </NavLink>
      <NavLink to={"/contact"} activeClassName="active-page">
        CONTACT
      </NavLink>
    </NavigationContainer>
  );
};

export default Navigation;
