import React from "react";
import styled from "@emotion/styled";
import BackgroundAnimation from "../animations/BackgroundAnimation";

const ViewerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const ContentViewer = ({ children }) => {
  return (
    <ViewerWrapper>
      {children}
      <BackgroundAnimation />
    </ViewerWrapper>
  );
};

export default ContentViewer;
